import React, { useContext, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  AdminProvider,
  AppSettingsContext,
  DetailToolbar2,
  EvidenceDefaultsProvider,
  ExportProvider,
  Menubar,
  MenubarContext,
  NamedSettingsProvider,
  NavigationContext,
  ReportingProvider,
  ScheduleProvider,
  SplitScreenMode,
  Tooltip,
  UserContext,
  UserSettingsProvider,
  useFetch,
} from '@eas/common-web';
import {
  KEP_PARTIAL_JOURNAL_ID,
  KEP_PRIMARY_JOURNAL_ID,
} from '@components/kep-signature/sign-partial-journal-kep-hook';
import logo from '@components/menu/logo.svg';
import { useMenuItems } from '@components/menu/menu-items';
import { NotificationButton } from '@components/notification/notification-button';
import { ProtectedRoute } from '@components/router/protected-route';
import {
  AbsenceListPermissions,
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  JournalEntryListPermissions,
  MechanismCategoryListPermissions,
  MechanismListPermissions,
  PartialJournalListPermissions,
  Permission,
  PrimaryJournalListPermissions,
  ProfessionListPermissions,
  TBPPListPermissions,
  TenantCode,
  WorkCategoryListPermissions,
  WorkListPermissions,
} from '@enums';
import { AppSettings } from './models';
//import { AppSettings } from './models';
import * as Module from './modules';
import { useExportTag } from './modules/export/export-api';

export const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
  },
  iconlink: {
    backgroundColor: 'white',
    padding: 3,
  },
  title: {
    fontWeight: 400,
    fontSize: 12,
    color: 'white',
    marginLeft: 10,
  },
  userButton: {
    fontSize: 12,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.tertiary.light,
    },
  },
  subMenuItem: {
    margin: 0,
  },
  subMenuItemIcon: {
    minWidth: 25,
  },
  menuContainer: {
    background: `linear-gradient(to right, ${theme.palette.primary.main} 20%, ${theme.palette.tertiary.main} 80%)`,
  },
}));

interface SystemInfo {
  version?: string;
  time?: string;
  name?: string;
}

export function AppSecure() {
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const { navigate } = useContext(NavigationContext);
  const { pathname } = useLocation();

  const menuRef = useRef<MenubarContext>(null);

  const [systemInfo] = useFetch<SystemInfo>(EvidenceApiUrl.SYSTEM_VERSION);

  useEffect(() => {
    if (user) {
      /* 
         const isAdmin = hasPermission(Permission.Default.ADMINISTRATOR);
      if (!isAdmin && !isOperator) {
        // logout();
      }
 */
      const KepPrimaryJournal = localStorage.getItem(KEP_PRIMARY_JOURNAL_ID);
      const KepPartialJournal = localStorage.getItem(KEP_PARTIAL_JOURNAL_ID);

      // If the ID of a primary journal exists in local storage and in url we have search params (added by external signing service), then we redirect to that particular primary journal.
      // Where the KEP signature is finished by the next api Call (in primary-journal-fields.tsx)

      if (KepPrimaryJournal && window.location.search) {
        navigate(
          `${EvidenceBrowserUrl.PRIMARY_JOURNAL}/${KepPrimaryJournal}${window.location.search}`
        );
      } else if (KepPartialJournal && window.location.search) {
        navigate(
          `${EvidenceBrowserUrl.PARTIAL_JOURNAL}/${KepPartialJournal}${window.location.search}`
        );
      }
    }

    if (!user) {
      window.sessionStorage.setItem('redirectTo', pathname);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ExportTag = useExportTag();
  const { filteredItems, userBtnActions } = useMenuItems(menuRef);

  const { settings } = useContext<AppSettingsContext<AppSettings>>(
    AppSettingsContext as any
  );

  return (
    <>
      {!user ? (
        <Redirect to={EvidenceBrowserUrl.LOGIN} />
      ) : (
        <ScheduleProvider
          jobUrl="/api/esd/schedule/jobs"
          runUrl="/api/esd/schedule/runs"
        >
          {window.sessionStorage.removeItem('redirectTo')}
          <ExportProvider url="/api/esd/export" tags={ExportTag}>
            <UserSettingsProvider url="/api/esd/user-settings">
              <NamedSettingsProvider url="/api/esd/named-settings">
                <EvidenceDefaultsProvider
                  appName="Elektronický stavebný denník (ESD) - Portál"
                  appLogo={logo}
                  enableEmptyDetailContainer={true}
                  ToolbarComponent={DetailToolbar2}
                  defaultTableNamedSettings={false}
                  splitScreen={+settings.evidenceMode ?? SplitScreenMode.SPLIT}
                >
                  <Menubar
                    ref={menuRef}
                    userBtnActions={userBtnActions}
                    logoUrl="/"
                    logo={<img src={logo} className={classes.logo} />}
                    title={
                      <Tooltip title={`Verzia: ${systemInfo?.version ?? ''}`}>
                        <Typography className={classes.title}>
                          <FormattedMessage
                            id="ESD__APP_TITLE"
                            defaultMessage="Elektronický stavebný denník (ESD) - Portál"
                          />
                        </Typography>
                      </Tooltip>
                    }
                    items={filteredItems}
                    classOverrides={{
                      iconlink: classes.iconlink,
                      userButton: classes.userButton,
                      subMenuItem: classes.subMenuItem,
                      subMenuItemIcon: classes.subMenuItemIcon,
                      menuContainer: classes.menuContainer,
                    }}
                    beforeUserBtn={<NotificationButton />}
                    displayLogoutBtn={false}
                  >
                    <AdminProvider
                      permission={Permission.Default.REINDEX_ALL}
                      prefix=""
                      reindexUrl="/api/esd/reindex"
                    />
                    <Switch>
                      <Route
                        path={EvidenceBrowserUrl.HOME}
                        exact
                        component={Module.EsdDashboard}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.PRIMARY_JOURNAL}/:id?`}
                        component={Module.PrimaryJournals}
                        permissions={PrimaryJournalListPermissions}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.PARTIAL_JOURNAL}/:id?`}
                        component={Module.PartialJournals}
                        permissions={PartialJournalListPermissions}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.JOURNAL_ENTRY}/:id?`}
                        component={Module.JournalEntries}
                        permissions={JournalEntryListPermissions}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.MECHANISM_CATEGORY}/:id?`}
                        component={Module.MechanismCategories}
                        permissions={MechanismCategoryListPermissions}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.MECHANISMS}/:id?`}
                        component={Module.Mechanisms}
                        permissions={MechanismListPermissions}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.WORK_CATEGORY}/:id?`}
                        component={Module.WorkCategories}
                        permissions={WorkCategoryListPermissions}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.WORK}/:id?`}
                        component={Module.Works}
                        permissions={WorkListPermissions}
                      />
                      {user?.tenant?.code === TenantCode.VSD && (
                        <ProtectedRoute
                          path={`${EvidenceBrowserUrl.TBPP}/:id?`}
                          component={Module.WorkTbpps}
                          permissions={TBPPListPermissions}
                        />
                      )}
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.PROFESSION}/:id?`}
                        component={Module.Professions}
                        permissions={ProfessionListPermissions}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.ROLES}/:id?`}
                        component={Module.Roles}
                        permissions={[Permission.Role.ROLE_LIST]}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.USERS}/:id?`}
                        component={Module.Users}
                        permissions={[Permission.User.USER_LIST]}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.SUPPLIER}/:id?`}
                        component={Module.Suppliers}
                        permissions={[Permission.Supplier.SUPPLIER_LIST]}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.PERSON}/:id?`}
                        component={Module.Person}
                        permissions={[Permission.Person.PERSON_LIST]}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.WORK_GROUP}/:id?`}
                        component={Module.WorkGroups}
                        permissions={[Permission.WorkGroup.WORKGROUP_LIST]}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.TENANTS}/:id?`}
                        component={Module.Tenants}
                        permissions={[Permission.Tenant.TENANT_LIST]}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.ABSENCE}/:id?`}
                        component={Module.Absences}
                        permissions={AbsenceListPermissions}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.ALOG}/:id?`}
                        component={Module.Alog}
                        permissions={[Permission.Default.ALOG_ALL]}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.SCHEDULE_JOBS}/:id?`}
                        component={Module.ScheduleJobs}
                        permissions={[Permission.Default.SCHEDULE_JOBS_ALL]}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.SCHEDULE_RUNS}/:id?`}
                        component={Module.ScheduleRuns}
                        permissions={[Permission.Default.SCHEDULE_RUNS_ALL]}
                      />
                      <ProtectedRoute
                        path={`${EvidenceBrowserUrl.SEQUENCES}/:id?`}
                        component={Module.Sequences}
                        permissions={[Permission.Default.SEQUENCE_ALL]}
                      />
                      <Route
                        path={`${EvidenceBrowserUrl.EXPORT_REQUESTS}/:id?`}
                        component={Module.ExportRequests}
                      />
                      <Route
                        path={`${EvidenceBrowserUrl.EXPORT_TEMPLATES}/:id?`}
                        component={Module.ExportTemplates}
                      />
                    </Switch>
                  </Menubar>
                </EvidenceDefaultsProvider>
              </NamedSettingsProvider>
            </UserSettingsProvider>
          </ExportProvider>
        </ScheduleProvider>
      )}
    </>
  );
}
